import { Button, Dialog, Icon } from "@blueprintjs/core";
import { DIALOG_BODY, DIALOG_FOOTER, DIALOG_FOOTER_ACTIONS } from "@blueprintjs/core/lib/esm/common/classes";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const PaymentSuccessModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const shp_session = searchParams.get("Shp_session");

  const handleCLose = () => {
    setIsOpen(false);
    navigate("/");
  };

  useEffect(() => {
    async function sendStatus() {
      if (!shp_session) {
        console.error("Shp_session not found");
        return;
      }
      try {
        const response = await fetch(`https://bba2gf9lu1gul7tlnukd.containers.yandexcloud.net/v1/payments/reports/success`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sessionPublicId: shp_session,
            status: "fail",
          }),
        });
        if (response.ok) {
          console.log("Payment status updated");
        } else {
          console.error(response);
        }
      } catch (e) {
        console.error(e);
      }
    }

    sendStatus();
  }, [shp_session]);

    return (
        <Dialog
      className="bp4-dark" // Add dark theme to the Dialog
      isOpen={isOpen}
      title="Оплата прошла успешно"
      onClose={handleCLose}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
    >
      <div className={DIALOG_BODY} style={{ textAlign: "center" }}>
      <Icon icon={"tick-circle"} iconSize={80} intent="success" style={{ marginBottom: "20px" }} />
        <p style={{ fontSize: "18px", lineHeight: "28px" }}>Спасибо за оплату! В скором времени вы получите билет на мастер-класс на указанную вами почту.</p>
      </div>
      <div className={DIALOG_FOOTER}>
        <div className={DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleCLose} intent="primary" style={{ fontSize: "16px" }}>
            Закрыть
          </Button>
        </div>
      </div>
    </Dialog>
    );
};
