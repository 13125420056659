import { Button, Card, H5, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const DownloadTicketRedirect: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloaded, setIsDownloaded] = useState(false);

  const redirectToRoot = () => {
    navigate("/");
  };

  useEffect(() => {
    const fetchPresignedUrl = async () => {
      try {
        const response = await fetch(`https://bba2gf9lu1gul7tlnukd.containers.yandexcloud.net/v1/tickets/ticket/download/${id}`);
        const data = await response.json();
        const presignedUrl = data.url;

        // Create an invisible <a> element with the download attribute
        const link = document.createElement("a");
        link.href = presignedUrl;
        link.download = "ticket.pdf";
        link.style.display = "none";
        document.body.appendChild(link);

        // Programmatically trigger a click on the link to initiate the download
        link.click();

        // Clean up the link element
        document.body.removeChild(link);

        setIsDownloaded(true);
      } catch (error) {
        console.error("Error fetching presigned URL:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPresignedUrl();
  }, [id]);

  return (
    <Card style={{ width: "400px", margin: "auto", marginTop: "100px", textAlign: "center" }}>
      {isLoading ? (
        <>
          <Spinner />
          <H5>Загрузка билета...</H5>
        </>
      ) : (
        <>
          {isDownloaded ? (
            <>
              <H5>Билет успешно загружен</H5>
              <Button onClick={redirectToRoot}>Вернуться на главную</Button>
            </>
          ) : (
            <H5>Произошла ошибка при загрузке билета</H5>
          )}
        </>
      )}
    </Card>
  );
};