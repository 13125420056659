import { H1, H2, H3, H4, H5 } from "@blueprintjs/core"
import { Footer } from "./Footer"

export const Policy = () => {
  return (
    <>
      <div className="container">
        <H1>Политика обработки персональных данных</H1>
        <H3>1.Общие положения</H3>
        <p>1.1  Настоящая  Политика  в  отношении  обработки  персональных  данных  (далее  –
          «Политика»)  подготовлена  в  соответствии  с  п.  2  ч. 1  ст.  18.1  Федерального  закона
          Российской Федерации «О персональных данных» No152-ФЗ от 27 июля 2006 года (далее –
          «Закон») и определяет позицию Индивидуального предпринимателя Осипова  Андрея
          Валерьевича (далее – ИП Осипов А.В.) в области обработки и защиты персональных
          данных (далее – персональные данные), в отношении соблюдения прав и свобод, описывает
          особенности  сбора,  хранения,  использования  и  передачи  персональных  данных,
          реализуемые требования к их защите, а также информацию о правах  лиц, к которым
          относятся соответствующие персональные данные.</p>
        <p>1.2 Под персональными данными понимается любая информация, относящаяся прямо или
          косвенно определенному или определяемому физическому лицу (гражданину). </p>
        <p>1.3 Под обработкой персональных данных понимается любое действие (операция) или
          совокупность  действий  (операций)  с  персональными  данными,  совершаемых  с
          использованием средств автоматизации и/или без использования таких средств. К таким
          действиям (операциям) относятся в частности: сбор, запись, систематизация, накопление,
          хранение,  уточнение  (обновление,  изменение),  извлечение,  использование,  передача
          (предоставление,  доступ),  сопоставление,  обезличивание,  блокирование,  удаление,
          уничтожение персональных данных.</p>
        <p>1.4 Под безопасностью персональных данных понимается защищенность персональных
          данных от неправомерного и/или несанкционированного доступа к ним, уничтожения,
          изменения, блокирования, копирования, предоставления, распространения персональных
          данных, а также от иных неправомерных действий в отношении персональных данных.</p>
        <p>1.5 Политика распространяется на все действия, связанные с обработкой персональных
          данных на сайте masterclass.todes.ru  (далее –  «Сайт») и Контакт-центром, в
          информационных системах ИП Осипова А.В.</p>
        <p>1.6  Использование  Сайта  или  Контакт-центра Пользователем  также  означает  их
          безоговорочное согласие с правилами Политики Конфиденциальности и указанными в ней
          условиями обработки персональных данных. Если Пользователь не согласен с условиями
          настоящей  Политики,  Пользователю  следует  воздержаться  от  использования  Сайта  и
          Контакт-центра.</p>
        <p>1.7 Настоящая Политика распространяется на персональные данные, полученные как до,
          так и после ввода в действие настоящей Политики.</p>
        <div style={{ marginTop: 5 }} />
        <H3>2.Правовые основания и цели обработки Персональных данных ИП Осиповым А.В.</H3>
        <p>2.1 ИП Осипов А.В. осуществляет обработку и обеспечивает безопасность персональных
          данных для осуществления возложенных на ИП Осипова А.В. законодательством России
          функций, полномочий и обязанностей в том числе, но не ограничиваясь, в соответствии с
          Конституцией Российской Федерации, федеральными законами, в частности Федеральным
          законом No152-ФЗ от 27 июля 2006 года "О персональных данных", подзаконных актов,
          других определяющих случаи и особенности обработки указанных персональных данных
          федеральных законов  Российской Федерации, руководящих и методических документов
          ФСТЭК России и ФСБ России, а также Гражданским кодексом Российской Федерации,
          Налоговым кодексом Российской Федерации, Законом РФ No2300-1 от 7 февраля 1992 г. "О
          защите прав потребителей", Федеральным законом No 402-ФЗ от 6 декабря 2011 г. "О
          бухгалтерском учете", а также операторами персональных данных, уставом и локальными
          актами ИП Осипова А.В.</p>
        <p>2.2 ИП Осипов А.В. является оператором персональных данных в соответствии с
          Законом.</p>
        <p>2.3 Обработка персональных данных может осуществляться ИП Осипова А.В. в
          следующих случаях и на следующих правовых основаниях: </p>
        <ul>
          <li>
            <p>обработка персональных данных осуществляется только с согласия субъекта
              персональных данных, выраженного в любой форме, позволяющей подтвердить
              факт получения согласия, в том числе способами, указанными в п. 1.6. настоящей
              Политики;</p>
          </li>
          <li>
            <p>
              для достижения целей, предусмотренных международным договором Российской
              Федерации или законом, для осуществления и выполнения возложенных
              законодательством Российской Федерации на оператора персональных данных
              функций, полномочий и обязанностей;
            </p>
          </li>
          <li>
            <p>
              для исполнения договора купли-продажи Товара  либо договора оказания Услуги
              между ИП Осипова А.В.  и пользователем Сайта, который является физическим
              лицо, обладающим необходимым объемом дееспособности и имеющим намерение
              оформить или оформляющим Заказ на Товар и (или) Услугу через Сайт;
            </p>
          </li>
          <li><p>
            для осуществления прав и законных интересов оператора или третьих лиц либо для
            достижения общественно значимых целей при условии, что при этом не
            нарушаются права и свободы субъекта персональных данных;</p></li>
          <li>
            <p>
              в статистических или иных маркетинговых и исследовательских целях при условии
              обязательного обезличивания персональных данных;
            </p>
          </li>

          <li><p>
            в иных целях, предусмотренных настоящей Политикой.</p></li>
        </ul>



        <p>
          2.4. Субъектами персональных данных, обрабатываемых посредством Сайта и работниками
          Контакт-центра, являются, в том числе пользователи Сайта, покупатели и получатели
          Товаров и (или)  Услуги (если покупатель указал в качестве получателя Товара  и (или)
          Услуги иное лицо); любые иные физические лица, обращающиеся к ИП Осипова А.В. с
          заявлениями /обращениями.
        </p>

        <p>2.5 ИП Осипов А.В. осуществляет обработку персональных данных покупателей для: </p>

        <p>2.5.1. заключения  и  исполнения  договоров,  одной  из  сторон  которых  является
          Пользователь, в соответствии с Условиями заказа и доставки Сайта, в том числе договора
          розничной купли-продажи Товара, в т. ч. заключенного дистанционным способом на Сайте,
          договора возмездного оказания услуг; </p>

        <p>2.5.2. предоставления  информации  о  Товарах, Услугах, Акциях  и  специальных
          предложениях; </p>

        <p>2.5.3 информирования о статусе Заказа; </p>

        <p>2.5.4. содействия в урегулировании претензий; </p>

        <p>2.5.5. организации процесса возврата Товаров; </p>

        <p>2.5.6. предоставление возможности оставлять отзывы о Товарах и/или Услугах; </p>

        <p>2.5.7. анализа качества предоставляемого ИП Осипова А.В. сервиса и улучшению
          качества обслуживания клиентов; </p>

        <p>2.5.8.  идентификации Пользователей и Покупателей в Акциях, обеспечения процедуры
          начисления, учета, использования бонусных баллов программ лояльности Пользователей и
          Покупателей.</p>

        <div style={{ marginTop: 5 }} />
        <H3>3. Категории персональных данных, обрабатываемых ИП Осиповым А.В.</H3>
        <p>3.1 ИП Осипов А.В. обрабатывает следующие категории персональных данных
          Пользователей, получателей Товара и (или) Услуги: </p>
        <ul>
          <li><p>сведения, полученные при регистрации, авторизации и оформлении заказа (фамилию, имя,
            отчество,  дата  рождения,  пол,  номер  телефона,  адрес  электронной  почты,  адрес
            регистрации, рабочий адрес, адрес доставки, cookie);</p></li>
          <li><p>- сведения, полученные при взаимодействии с Покупателями (пол, возраст Покупателя,
            паспортные данные, данные платежных средств, город проживания и/или адрес);</p></li>
          <li><p>сведения, полученные в связи с размещением или исполнением Заказов Покупателя
            (сведения о способе доставки Товара и/или оказания Услуги, способе и состоянии оплаты
            Товара  и/или Услуги, а если конечный получатель Товара  и/или Услуги  отличается от
            Покупателя,  также  фамилию,  имя,  отчество,  адрес  доставки  и  номер  телефона
            выгодоприобретателя);</p></li>
          <li><p>- сведения о претензиях Покупателя (переданных Покупателем с помощью Сайта, Контакт-
            центра или иным образом);</p></li>
          <li>
            <p>размещенные Покупателем отзывы о Товарах и/или Услуг;</p>
          </li>
          <li><p>информацию о геолокации.</p></li>
        </ul>

        <p>3.2 ИП Осипов А.В. обрабатывает следующие категории персональных данных
          субъектов  персональных  данных,  обращающихся  с  заявлениями  о  предполагаемом
          нарушении их прав: фамилия, имя, отчество, паспортные данные (данные иного документа,
          удостоверяющего личность), контактная информация (номера телефона и (или) факса,
          адрес электронной почты) правообладателя или иного лица, чье право предположительно
          было  нарушено,  и  (или)  заявителя,  если  он  действует  в  качестве  уполномоченного
          представителя  правообладателя  или  иного  лица,  чье  право  предположительно  было
          нарушено, информацию о полученных заявлениях, ходе и результате их рассмотрения. </p>

        <p>3.3 Персональные данные, указанные в пунктах настоящего Раздела выше, могут быть
          получены ИП Осиповым А.В. в ходе предоставления  ПОКУПАТЕЛЕМ персональных
          данных путем заполнения соответствующих форм на Сайте, посредством направления
          корреспонденции или электронных писем на адреса электронной почты ИП Осипова А.В. </p>
        <div style={{ marginTop: 5 }} />
        <H3>4.Принципы и условия обработки Персональных Данных</H3>
        <p>4.1 При обработке персональных данных ИП Осипов А.В. придерживается следующих
          принципов: </p>
        <ul>
          <li>
            <p>обработка персональных данных осуществляется на законной основе;</p>
          </li>
          <li><p>персональные  данные  не  раскрываются  третьим  лицам  и  не  распространяются  без
            согласия  субъекта  персональных  данных,  за  исключением  предусмотренных
            законодательством России случаев;</p></li>
          <li><p>определение конкретных законных целей до начала обработки (в т. ч. сбора) персональных
            данных;</p></li>
          <li><p>объединение баз данных, в том числе, баз данных, содержащих персональные данные,
            обработка которых осуществляется в целях, несовместимых между собой, не допускается;</p></li>
          <li><p>обработка  персональных  данных  ограничивается  достижением  конкретных,  заранее
            определенных и законных целей;</p></li>
          <li><p>обрабатываемые персональные данные подлежат уничтожению или обезличиванию при
            отзыве согласия на их обработку субъектом персональных данных, по достижению целей
            обработки или в случае утраты необходимости в достижении этих целей, если иное не
            предусмотрено федеральным законом;</p></li>
          <li><p>содержание и объем обрабатываемых персональных данных соответствуют заявленным
            целям обработки. Обрабатываемые персональные данные  не являются избыточными по
            отношению к заявленным целям обработки;</p></li>
          <li><p>при обработке персональных данных обеспечивается точность персональных данных и их
            достаточность,  в  случаях  необходимости  и  актуальность  персональных  данных  по
            отношению к заявленным целям их обработки;</p></li>
          <li><p>хранение  персональных  данных  осуществляется  в  форме,  позволяющей  определить
            субъекта  персональных  данных  не  дольше,  чем  этого  требуют  цели  обработки
            персональных  данных,  если  срок  хранения  персональных  данных  не  установлен
            федеральным  законом,  договором,  стороной  которого,  выгодоприобретателем  или
            поручителем, по которому является субъект персональных данных.</p></li>
        </ul>
        <p>4.2 ИП  Осипов  А.В.  не  осуществляет  обработку  персональных  данных,  касающихся
          расовой,  национальной  принадлежности,  политических  взглядов,  религиозных,
          философских  и  иных  убеждений,  состояния  здоровья,  личной  жизни,  членства  в
          общественных объединениях, в том числе в профессиональных союзах, биометрических
          данных. ИП Осипов А.В. не осуществляет голосовой ввод и верификацию персональных
          данных с использованием незащищенных каналов передачи данных. Передача данных
          субъектами  персональных  данных  работникам  Контакт-центр  носит  добровольный
          характер и подразумевает, что полученные данные будут использованы в соответствии с
          настоящей Политикой. </p>
        <p>4.3 ИП  Осипов  А.В.  обеспечивает  запись,  систематизацию,  накопление,  хранение,
          уточнение (обновление, изменение), извлечение персональных данных с использованием
          баз данных, находящихся на территории России, и не осуществляет трансграничную
          передачу персональных данных. </p>
        <p>4.4 ИП  Осипов  А.В.  вправе  поручить  обработку  персональных  данных   посредством
          совершения действий,  указанных в п. 1.3. настоящей Политики третьим лицам, в том
          числе третьим лицам, перечисленным в п. 4.5. настоящей Политики, с согласия субъекта
          персональных  данных,  выраженного  путем  предоставления  согласия,  на  основании
          заключаемого с такими третьими лицами договора, в следующих случаях: </p>
        <ul><li><p>для обработки обращений Покупателей и (или) Пользователей, третьих лиц посредством
          голосовых и неголосовых каналов связи, включая электронную почту, онлайн-чат,
          приложения для мобильных устройств и (или) социальные сети;</p></li>
          <li><p>в целях доставки Товара Покупателям и Получателям;</p></li>
          <li><p>в целях анализа качества предоставляемого ИП Осиповым А.В.  сервиса и улучшению
            качества обслуживания клиентов (Пользователей и Покупателей);</p></li>
          <li><p>в целях подготовки персональных предложений и условий обслуживания;</p></li>
          <li><p>в целях персонализации рекламы;</p></li>
          <li><p>в целях отправки рекламных и информационных сообщений;</p></li>
          <li><p>в целях проведения статистических или иных маркетинговых исследований.</p></li></ul>
        <p>4.5. ИП Осипов А.В.  вправе передавать персональные данные третьим лицам с согласия
          субъекта персональных данных в следующих случаях: </p>
        <p>4.5.1 Службам доставки для целей исполнения Заказа Покупателя, в том числе, в случае
          получения требования о возврате Товара, приобретенного на Сайте, в целях рассмотрения
          требований и возврате и осуществления возврата, в случаях, предусмотренных Правилами
          заказа и иными разделами Сайта, в том числе при условии получения ИП Осиповым А.В.
          заявления  о  нарушении  интеллектуальных  или  иных  прав  третьих  лиц,  в  целях
          урегулирования полученных претензий, в случае получения иных заявлений, отзывов,
          претензий,  жалоб,  сообщений  или  документов,  в  иных  случаях  и  целях,  согласно
          описанным в Правилах заказа; </p>
        <p>4.5.2 в целях возможности защиты прав и законных интересов ИП Осипова А.В.   или
          третьих лиц в случаях, когда субъект персональных данных нарушает какие-либо условия
          пользовательских документов Сайта и (или) настоящей Политикой; </p>
        <p>
          4.5.3  по  вступившему  в  силу  судебному  решению  или  в  случаях,  установленных
          действующим законодательством;
        </p>
        <p>4.6 В целях  улучшения  качества  обслуживания  Пользователей  и  Покупателей,
          статистических или иных маркетинговых и исследовательских целях, ИП Осипов А.В.
          развивает,  совершенствует,  оптимизирует  и  внедряет  новый  функционал  Сайта.  Для
          обеспечения  указанного, Пользователь  или  Покупатель  соглашается  и  поручает  ИП
          Осипову А.В. осуществлять с соблюдением применимого законодательства обработку
          (включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
          изменение),  сопоставление,  извлечение,  использование,  обезличивание,  блокирование,
          удаление и уничтожение) учетных и любых иных персональных данных Пользователя или
          Покупателя,  в  том  числе  имеющихся  в  распоряжении  партнера,  действующего  по
          поручению ИП Осипова А.В., передачу имеющихся в распоряжении ИП Осипова А.В.
          учетных и любых иных персональных данных Пользователя или Покупателя партнерам во
          исполнение такого поручения на обработку и получение от партнеров по итогам обработки
          аналитической информации, включая результатов автоматизированной обработки таких
          данных, в том числе в виде целочисленных и/или текстовых значений и идентификаторов. </p>
        <p>
          4.7 ИП Осипов А.В. вправе передавать персональные данные третьим лицам с согласия
          субъекта  персональных  данных,  а  также  в  случаях,  когда  возможность  передачи
          персональных данных третьим лицам прямо предусмотрена законодательством России не
          требуют согласия субъекта персональных данных.
        </p>
        <p>4.8  В  целях  исполнения  требований  действующего  законодательства  России  и  своих
          договорных обязательств обработка персональных данных ИП Осиповым А.В.
          осуществляется как с использованием, так и без использования средств автоматизации.
          Совокупность операций обработки включает сбор, запись, систематизацию, накопление,
          хранение,  уточнение  (обновление,  изменение),  извлечение,  использование,  передачу
          (предоставление,  доступ),  обезличивание,  блокирование,  удаление,  уничтожение
          персональных данных. </p>
        <p>4.9 ИП Осипов А.В. не осуществляет принятия решений, порождающих юридические
          последствия  в  отношении  субъекта  персональных  данных  или  иным  образом
          затрагивающих  его  права  и  законные  интересы,  на  основании  исключительно
          автоматизированной обработки персональных данных. </p>
        <p>4.10 ИП Осипов А.В.  не проверяет и, как правило, не имеет возможности проверить
          актуальность  и  достоверность  предоставляемой  субъектами  персональных  данных
          сведений, полученных посредством Сайта. ИП Осипов А.В. исходит из того, что субъекты
          персональных данных, действуя разумно и добросовестно, предоставляют достоверные и
          достаточные персональные данные и поддерживают их в актуальном состоянии. </p>
        <H3>5. Права субъектов персональных данных</H3>
        <p>
          5.1 Субъект персональных данных, персональные данные которого обрабатываются ИП
          Осиповым А.В., имеет право:
        </p>
        <ul>
          <li><p>в любой момент изменить (обновить, дополнить) предоставленные им
            персональные данные, зайдя в Личный кабинет в случаях, когда это позволяет
            сделать функционал Сайта;</p></li>
          <li><p>удалить предоставленные им персональные данные путем направления ИП Осипова
            А.В.  письменного уведомления с указанием персональных данных, которые
            должны быть удалены;</p></li>
          <li><p>получать от ИП Осипова А.В.:</p></li>
          <li><p>подтверждение факта обработки персональных данных и сведения о наличии
            персональных данных, относящихся к соответствующему субъекту персональных данных;</p></li>
          <li><p>сведения о правовых основаниях и целях обработки персональных данных;</p></li>
          <li><p>сведения о применяемых ИП Осиповым А.В. способах обработки персональных
            данных;</p></li>
          <li><p>сведения о наименовании и местонахождении ИП Осипова А.В.;</p></li>
          <li><p>сведения о лицах (за исключением работников ИП Осипова А.В.), которые имеют
            доступ к персональным данным или которым могут быть раскрыты персональные данные
            на основании договора с ИП Осиповым А.В.  или на основании федерального закона;</p></li>
          <li><p>обрабатываемые персональные данные, относящиеся к субъекту персональных данных,
            и информацию об источнике их получения, если иной порядок предоставления таких
            персональных данных не предусмотрен законодательством;</p></li>
          <li><p>сведения о сроках обработки персональных данных, в том числе о сроках их хранения;</p></li>
          <li><p>сведения о порядке осуществления субъектом персональных данных прав,
            предусмотренных законодательством о персональных данных;</p></li>
          <li><p>наименование и адрес лиц, осуществляющих обработку персональных данных по
            поручению ИП Осипова А.В.;</p></li>
          <li><p>иные сведения, предусмотренные Законом;</p></li>
          <li><p>требовать от ИП Осипова А.В. уточнения своих персональных данных, их
            блокирования или уничтожения в случае, если персональные данные являются
            неполными, устаревшими, неточными, незаконно полученными или не являются
            необходимыми для заявленной цели обработки;</p></li>
          <li><p>отозвать свое согласие на обработку персональных данных в любой момент,
            направив электронное сообщение с электронной подписью по адресам электронной
            почты master-class@todes.ru либо направив письменное уведомление по адресу ИП
            Осипова А.В., указанному на Сайте. После получения такого сообщения обработка
            персональных данных субъекта персональных данных будет прекращена, а его
            персональные данные будут удалены, за исключением случаев, когда обработка
            может быть продолжена в соответствии с законодательством Российской
            Федерации. При этом направление такого сообщения об отзыве согласия
            Пользователем и(или) Покупателем также должно считаться уведомлением об
            одностороннем отказе от исполнения соответствующего Заказа и влечет
            невозможность его исполнения;</p></li>
          <li><p>требовать устранения неправомерных действий ИП Осипова А.В.    в отношении его
            персональных данных;</p></li>
          <li><p>принимать предусмотренные законом меры по защите своих прав и законных
            интересов, в том числе на возмещения убытков и (или) компенсацию морального
            вреда в судебном порядке.</p></li>
        </ul>
        <p>5.2  Права,  предусмотренные  подпунктами  настоящего  Раздела  выше,  могут  быть
          ограничены в соответствии с требованиями законодательства России и (или) в случаях,
          когда ИП Осипов А.В. осуществляет обработку персональных данных на иных
          правовых основаниях, нежели согласие субъекта персональных данных. </p>
        <H3>6. Обязанности ИП Осипова А.В.</H3>
        <H4>В соответствии с требованиями Закона ИП Осипов А.В. обязан: </H4>
        <p>6.1 предоставлять субъекту персональных данных по его запросу информацию, касающуюся
          обработки его персональных данных, либо на законных основаниях предоставить отказ в
          течение тридцати дней с даты получения запроса субъекта персональных данных или его
          представителя;</p>
        <p>6.2 по требованию субъекта персональных данных уточнять, блокировать или удалять
          обрабатываемые персональные данные, если персональные данные являются неполными,
          устаревшими, неточными, незаконно полученными или не являются необходимыми для
          заявленной  цели  обработки  в  срок,  не  превышающий  семи  рабочих  дней  со  дня
          предоставления  субъектом  персональных  данных  или  его  представителем  сведений,
          подтверждающих эти факты;</p>
        <p>6.3 вести журнал учета обращений субъектов персональных данных, в котором должны
          фиксироваться  запросы  субъектов  персональных  данных  на  получение  персональных
          данных, а также факты предоставления персональных данных по этим запросам;</p>
        <p>6.4 уведомлять субъекта персональных данных об обработке персональных данных в том
          случае, если персональные данные были получены не от субъекта персональных данных.
          Исключение составляют следующие случаи:</p>
        <ul><li><p>субъект персональных данных уведомлен об осуществлении обработки ИП Осипова А.В.
          его персональных данных;</p></li>
          <li><p>персональные данные получены ИП Осиповым А.В.  в связи с исполнением договора,
            стороной которого либо выгодоприобретателем или поручителем,  по которому является
            субъект персональных данных или на основании требования действующего
            законодательства;</p></li>
          <li><p>персональные данных сделаны общедоступными субъектом персональных данных или
            получены из общедоступного источника;</p></li>
          <li><p>ИП Осипов А.В.  осуществляет обработку обезличенных персональных данных для
            статистических или иных исследовательских целей, если при этом не нарушаются права и
            законные интересы субъекта персональных данных;</p></li>
          <li><p>предоставление субъекту персональных данных сведений, содержащихся в уведомлении об обработке персональных данных, нарушает права и законные интересы третьих лиц;</p></li></ul>
        <p>6.5 в случае достижения цели обработки персональных данных незамедлительно прекратить
          обработку персональных данных и уничтожить соответствующие персональные данные в
          срок, не превышающий тридцати дней с даты достижения цели обработки персональных
          данных, если иное не предусмотрено договором, стороной которого,
          выгодоприобретателем или поручителем по которому является субъект персональных
          данных, иным соглашением между ИП Осиповым А.В. и субъектом персональных данных
          либо если ИП Осипов А.В. не вправе осуществлять обработку персональных данных без
          согласия субъекта персональных данных на основаниях, предусмотренных Законом или
          другими федеральными законами;</p>
        <p>6.6 в случае отзыва субъектом персональных данных согласия на обработку своих
          персональных данных прекратить обработку персональных данных и уничтожить
          персональных данных в срок, не превышающий тридцати дней с даты поступления
          указанного отзыва, если иное не предусмотрено соглашением между ИП Осиповым А.В.  и
          субъектом персональных данных. Об уничтожении персональных данных ИП Осиповым
          А.В. обязана уведомить субъекта персональных данных;</p>
        <p>6.7 в случае поступления требования субъекта персональных данных о прекращении
          обработки персональных данных, полученных в целях продвижения товаров, работ, услуг
          на рынке, немедленно прекратить обработку персональных данных;</p>
        <p>6.8 при сборе персональных данных, в том числе посредством сети "Интернет", ИП Осипов
          А.В. обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление,
          изменение), извлечение персональных данных граждан Российской Федерации с
          использованием баз данных, находящихся на территории Российской Федерации.</p>
        <H3>7.Защита персональных данных</H3>
        <p>7.1  ИП Осипов А.В.  при обработке персональных данных принимает необходимые
          правовые, организационные и технические меры для защиты персональных данных от
          неправомерного и (или) несанкционированного доступа к ним, уничтожения, изменения,
          блокирования, копирования, предоставления, распространения персональных данных, а
          также от иных неправомерных действий в отношении персональных данных. </p>
        <p>7.2 К таким мерам, в частности, относятся: </p>
        <ul><li><p>назначение лица, ответственного за организацию обработки персональных данных,
          и лица, ответственного за обеспечение безопасности данных;</p></li>
          <li><p>разработка и утверждение локальных актов по вопросам обработки и защиты
            персональных данных;</p></li>
          <li><p>применение правовых, организационных и технических мер по обеспечению
            безопасности персональных данных.</p></li>
          <li><p>контроль за принимаемыми мерами по обеспечению безопасности персональных
            данных и уровнем защищенности информационных систем персональных данных;</p></li>
          <li><p>оценка вреда, который может быть причинен субъектам персональных данных в
            случае нарушения требований законодательства о персональных данных,
            соотношение указанного вреда и принимаемых ИП Осиповым А.В.  мер,
            направленных на обеспечение выполнения обязанностей, предусмотренных
            законодательством о персональных данных;</p></li>
          <li><p>соблюдение условий, исключающих несанкционированный доступ к материальным
            носителям персональных данных и обеспечивающих сохранность персональных
            данных;</p></li>
          <li><p>ознакомление работников ИП Осипова А.В., непосредственно осуществляющих
            обработку персональных данных, с положениями законодательства России о
            персональных данных, в том числе с требованиями к защите персональных данных,
            локальными актами по вопросам обработки и защиты персональных данных, и
            обучение работников ИП Осипова А.В.</p></li></ul>
        <H3>8. Сроки обработки (хранения) Персональных Данных</H3>
        <p>8.1 Сроки обработки (хранения) персональных данных определяются исходя из целей
          обработки  персональных  данных,  в  соответствии  со  сроком  действия  договоров  с
          субъектами персональных данных, требованиями действующего законодательства: </p>
        <ul><li><p>персональные данные зарегистрированных Пользователей хранятся в течение всего срока
          действия Учетной записи Пользователя;</p></li>
          <li><p>персональные данные незарегистрированного Пользователя и получателя Товара (если в
            качестве получателя Товара указано иное лицо) хранятся в течение трех лет с момента
            исполнения Заказа такого Пользователя;</p></li>
          <li><p>Персональные данные иных лиц, обращающихся к  ИП Осипову А.В. с заявлениями о
            предполагаемом нарушении их прав, в течение всего срока обработки и рассмотрения
            соответствующих претензий и в течение трех лет с момента завершения обработки и (или)
            рассмотрения претензии, если законодательством не предусмотрен иной срок исковой
            давности для соответствующих споров.</p></li></ul>
        <p>8.2  Персональные  данные,  срок  обработки  (хранения)  которых  истек,  должны  быть
          уничтожены,  если  иное не  предусмотрено  законодательством  Российской  Федерации.
          Хранение персональных данных после прекращения их обработки допускается только
          после их обезличивания. </p>
        <H3>9. Порядок получения разъяснений по вопросам обработки Персональных Данных</H3>
        <p>9.1  Субъекты  персональных  данных,  чьи  персональные  данные  обрабатываются  ИП
          Осиповым А.В., могут получить разъяснения по вопросам обработки своих персональных
          данных, обратившись лично к ИП Осипову А.В. или направив соответствующий
          письменный запрос по адресу местонахождения ИП Осипова А.В. </p>
        <H3>10. Автоматически собираемая информация</H3>
        <p>10.1 ИП Осипов А.В.  может собирать и обрабатывать следующие сведения, в том числе
          сведения, не являющиеся персональными данными: </p>
        <ul><li><p>информацию об интересах Пользователей на Сайте на основе введенных поисковых
          запросов пользователей Сайта о реализуемых и предлагаемых к продаже товаров с целью
          предоставления актуальной информации Пользователям при использовании Сайта, а также
          обобщения и анализа информации, о том какие разделы Сайта и товары пользуются
          наибольшим спросом у Пользователей Сайта;</p></li>
          <li><p>информацию  об  интересах  Пользователей  Контакт-Центра  на  основе  полученных
            обращений  Пользователей  Контакт-Центра  с  целью  предоставления  актуальной
            информации Пользователям при использовании Контакт-Центра, а также обобщения и
            анализа информации, о том какие возможности Контакт-Центра пользуются наибольшим
            спросом у Пользователей Контакт-Центра;</p></li></ul>
        <p>10.2 ИП  Осипов  А.В.  осуществляет  обработку  и  хранение  поисковых  запросов
          Пользователей  Сайта  с  целью  обобщения, и  создания  клиентской  статистики  об
          использовании разделов Сайта. </p>
        <p>10.3 ИП Осипов А.В. автоматически получает некоторые виды информации, получаемой в
          процессе взаимодействия Пользователей с Cайтом, переписки по электронной почте и т.п.
          Речь идет о технологиях и сервисах, таких как веб-протоколы, Cookies, Веб-отметки, а
          также приложения и инструменты третьих лиц. При этом Веб-отметки, Cookies и другие
          мониторинговые технологии не дают возможность автоматически получать персональные
          данные. Если Пользователь Сайта по своему усмотрению предоставляет свои персональные
          данные, например, при заполнении формы обратной связи или при отправке электронного
          письма,  то  только  тогда  запускаются  процессы  автоматического  сбора  подробной
          информации  для  удобства  пользования  веб-сайтами  и  (или)  для  совершенствования
          взаимодействия с пользователями. </p>
        <p>10.4 Если ИП Осипов  А.В.  может разумно соотнести указанные в настоящем Разделе
          сведения  с  Учетной  записью  конкретного Пользователя, то  такие  сведения  могут
          обрабатываться  совместно  с  Персональными  Данными  и  иной  личной  информацией
          такого Пользователя. </p>
        <H3>11. Изменения Политики. Прочие положения</H3>
        <p>11.1 ИП Осипов А.В. может периодически вносить изменения в настоящую Политику, в
          том числе, чтобы отражать изменения в объеме оказываемых ИП Осиповым А.В. услуг или
          изменения  в  функциональности  Сайта,  а  также  изменения  законодательства.  Новая
          редакция указанных выше положений и конфиденциальности вступает в силу с момента ее
          опубликования, если в ней не указан иной срок ее вступления в силу. </p>
      </div>
      <Footer />
    </>
  )
}
