import { Button, Navbar, Tabs } from "@blueprintjs/core";
import { SetStateAction, useEffect, useState} from "react";

export const Footer = () => {
  const [visaImageLoaded, setVisaImageLoaded] = useState(false);
  const [mastercardImageLoaded, setMastercardImageLoaded] = useState(false);
  const [mirImageLoaded, setMirImageLoaded] = useState(false);

  useEffect(() => {
    const loadImages = (imageSource: string, setImageLoaded: { (value: SetStateAction<boolean>): void; (value: SetStateAction<boolean>): void; (value: SetStateAction<boolean>): void; (arg0: boolean): any; }) => {
      const image = new Image();
      image.src = imageSource;
      image.onload = () => setImageLoaded(true);
    };

    loadImages("/visa.png", setVisaImageLoaded);
    loadImages("/ma.svg", setMastercardImageLoaded);
    loadImages("/mir.SVG.svg", setMirImageLoaded);
  }, []);

  // Render null while images are loading
  if (!visaImageLoaded || !mastercardImageLoaded || !mirImageLoaded) return null;

  return (
      <Navbar className="footer">
        <div className="footer-content">
          <Tabs animate={true}>
            <a href={'/offer'}>
              <Button minimal={true}>Публичная оферта</Button>
            </a>
            <a href={'/policy'}>
              <Button minimal={true}>Политика обработки персональных данных</Button>
            </a>
          </Tabs>
          <div className="payment-logos">
            <img src="/visa.png" alt="Visa" className="payment-logo" />
            <img src="/ma.svg" alt="Mastercard" className="payment-logo" />
            <img src="/mir.SVG.svg" alt="Mir" className="payment-logo" />
          </div>
        </div>
      </Navbar>
  );
};
