import { Button, Card, H1, H3, H4, Tag } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import EventsCard from "./EventsCard";
import { Footer } from "./Footer";

export const SiteUnderConstructionCard = () => {
  // Existing states
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Existing images loading
    const image = new Image();
    image.src = "/work-deadline-4.svg";
    image.onload = () => setImageLoaded(true);
  }, []);

  // Return null or loading spinner if images are not loaded
  if (!imageLoaded) {
    return null; // or return a loading spinner or any placeholder content
  }

  return (
      <div className="site-container">
        <div className="container">
          <img
              src="/work-deadline-4.svg"
              alt="Work Deadline"
              className="deadline-image" // Use className for styling
          />
          <H1 className="card-header">РАСПИСАНИЕ МАСТЕР-КЛАССОВ</H1>
          <div className="row">
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>МОСКВА</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>СОЛИСТЫ БАЛЕТА TODES</Tag>
              <H3>МАСТЕР-КЛАСС АНАСТАСИИ СОСЛОВСКОЙ</H3>
              <H4>10 октября 2024</H4>
              <p className="event-description">
                Грация и лёгкость Анастасии не оставляют равнодушным никого, кто когда-либо видел её на сцене.
                Мастер-класс станет возможностью не только научиться новым движениям, но и прикоснуться к миру
                профессионального танца! Независимо от уровня подготовки каждый участник сможет раскрыть свой потенциал
                под чутким руководством одной из лучших танцовщиц страны.
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/9C34WE")
                  }
              />
            </Card>
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>САРАТОВ</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>СОЛИСТЫ ТЕАТРА TODES</Tag>
              <H3>МАСТЕР-КЛАСС ЮЛИИ ФИЛИППОВОЙ-БУГАКОВОЙ</H3>
              <H4>20 октября 2024</H4>
              <p className="event-description">
                Мастер-класс от ведущей солистки Театра TODES Юлии Филипповой для детей старше 12 лет.
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/RYLJG3")
                  }
              />
            </Card>
          </div>
          <div className="row">
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>САРАТОВ</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>LADY</Tag>
              <H3>МАСТЕР-КЛАСС ЮЛИИ ФИЛИППОВОЙ-БУГАКОВОЙ</H3>
              <H4>20 октября 2024</H4>
              <p className="event-description">
                Мастер-класс от ведущей солистки Театра TODES Юлии Филипповой - это воплощение гармонии, женственности и
                спортивной закалки. На своем уроке она раскроет секреты выразительности движений и поможет каждому
                развить свою индивидуальность в танце. Присоединяйтесь и откройте новые грани своего таланта!
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/13K5PV")
                  }
              />
            </Card>
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>МОСКВА</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>ТАНЦЕВАЛЬНЫЕ МАСТЕРА</Tag>
              <H3>ТАНЦЕВАЛЬНЫЕ МАСТЕРА В МОСКВЕ</H3>
              <H4>20 октября 2024</H4>
              <p className="event-description">
                Танцевальные мастера помогают раскрытьтвой танцевальный потенциал.10 из 10 посетивших прошлые ТМ
                показали рывок в развитии среди своих сверстников. И были отмечены педагогами. Возможность танцевать
                перед многочисленной аудиторией в непринуждённой обстановке помогает преодолеть страх сцены и развивает
                уверенность в своих силах. Более 5 часовнепрерывного танцевального роста в кругу единомышленников.
                Вдохновение, заряд энергией на новые репетиции, улучшение чувства ритма и понимания музыкальных
                акцентов.Присоединяйся!
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/VR2AXR")
                  }
              />
            </Card>
          </div>
          <div className="row">
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>КЕМЕРОВО</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>АРТИСТЫ БАЛЕТА TODES</Tag>
              <H3>МАСТЕР-КЛАСС ВАДИМА МАРЧЕНКОВА</H3>
              <H4>21 октября 2024</H4>
              <p className="event-description">
                Опытный хореограф, обладающий невероятным профессионализмом и харизмой, погрузит вас в мир танца,
                раскрывая секреты мастерства и техники.Вадим вдохновит вас своей гибкостью и легкостью движений, помогая
                каждому участнику почувствовать себя уверенно и раскрепощенно. Не упустите возможность обучиться у
                настоящего мастера и вывести свои танцевальные навыки на новый уровень!
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/YEN1VP")
                  }
              />
            </Card>
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>БАРНАУЛ</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>АРТИСТЫ БАЛЕТА TODES</Tag>
              <H3>МАСТЕР-КЛАСС КОЛЛАБОРАЦИЯ АРТИСТОВ БАЛЕТА TODES</H3>
              <H4>24 октября 2024</H4>
              <p className="event-description">
                Коллаборация Артёма и Валерии представляет собой сочетание двух стилей. Их разнообразная манера работы
                создаст уникальную динамику и обеспечит участникам мастер-класса неповторимый опыт. Вместе они создадут
                атмосферу, в которой каждый сможет раскрыть свой потенциал и получить незабываемый опыт.
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/ZVVPBV")
                  }
              />
            </Card>
          </div>
          <div className="row">
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>ВОЛГОГРАД</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>AFRO TODES</Tag>
              <H3>МАСТЕР-КЛАСС NICK ALIXTER</H3>
              <H4>27 октября 2024</H4>
              <p className="event-description">
                Nick Alixter - ярчайший представитель стиля Ndombolo Brazzaville родом из Кот-д’Ивуар. Мастер-класс для лучшего погружения в стиль и атмосферу культуры континента. Уникальный опыт контроля над телом, погружение в танцевальное искусство страны, технические тонкости исполнения. Присоединяйтесь к увлекательному путешествию в мир африканских традиций через танец!
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/QJRSBB")
                  }
              />
            </Card>
          </div>
        </div>
        {/*<EventsCard/>*/}
        <Footer/>
      </div>
  );
};