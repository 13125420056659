import {
  Button,
  Callout,
  Navbar,
  NavbarGroup,
  NavbarHeading,
} from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";

export const MainNavbar = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar>
        <NavbarGroup align="left">
          <NavbarHeading>
            <a href="/" style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/mk_logo_horizontal.png"
                alt="MK Logo"
                style={{ width: 210, marginRight: 15 }}
              />
            </a>
          </NavbarHeading>
        </NavbarGroup>
        <NavbarGroup align="right">
          <Button
            text="Контакты"
            onClick={() => navigate("/contacts")}
            minimal
          />
        </NavbarGroup>
      </Navbar>
      <Callout
        title="Внимание"
        intent="warning"
        // style={{ marginBottom: "15px" }}
      >
        Сайт находится в стадии разработки.
      </Callout>
    </div>
  );
};
