import { H1, H3 } from "@blueprintjs/core"
import { Footer } from "./Footer"

export const Offer = () => {
  return (
    <>
      <div className="container">
        <H1>Публичная оферта</H1>
        <H3>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</H3>
        <p>1.1. Оферта – настоящее предложение о заключении договора возмездного оказания
          услуг. </p>
        <p>1.2. Договор – договор возмездного оказания услуг, заключенный между
          Исполнителем и Заказчиком посредством акцепта последним Оферты в
          установленном ей порядке. </p>
        <p>1.2. Исполнитель – индивидуальный предприниматель Осипов Андрей Валерьевич
          (ИНН 246525171833, ОГРНИП 315246800024196, адрес: 660005 г. Красноярск ул.
          Малиновского 27, кв. 11). </p>
        <p>1.3. Заказчик – любое физическое лицо, акцептовавшее Оферту в установленном ей
          порядке. </p>
        <p>1.4. Ученик – физическое лицо, в пользу которого заключен Договор, являющееся
          непосредственным потребителем услуг Исполнителя (участником мастер-класса).
          Ученик и Заказчик могут совпадать в одном лице. </p>

        <H3>2. ОФЕРТА И АКЦЕПТ </H3>
        <p>2.1. Акцепт Оферты может быть совершён только посредством перечисления на
          банковский счёт Исполнителя посредством сервиса по обработке он-лайн платежей
          Robokassa.ru с использованием всех указанных в Оферте платёжных реквизитов
          денежной суммы в размере, соответствующем указанной в Оферте цене услуг. </p>
        <p>2.2. Совершая акцепт, Заказчик подтверждает, что он полностью ознакомился с
          условиями Оферты посредством доступа к веб-странице по адресу:
          ttps://docs.robokassa.ru/оферта-бизнес-элемент/ и выражает своё полное согласие на
          заключение Договора на указанных в Оферте условиях. </p>
        <p>2.3. Оферта может быть акцептована до 31.12.2018. </p>
        <p>2.4. Договор не считается заключенным (Оферта не считается акцептованной) до
          полной оплаты Заказчиком услуг в полном соответствии с порядком,
          установленным п. 2.1 Оферты. </p>
        <p>2.5. Договор не считается заключенным, если акцепт был совершён с опозданием,
          если только Исполнитель немедленно не уведомит Заказчика о своём согласии
          принять опоздавший акцепт. </p>

        <H3>3. ПРЕДМЕТ ДОГОВОРА </H3>
        <p>3.1. Исполнитель обязуется оказать Заказчику услуги по предоставлению Ученику
          возможности участия в танцевальном мастер-классе «ТОДЕС», а Заказчик обязуется
          принять и оплатить оказанные услуги. </p>

        <H3>4. ПОРЯДОК ОКАЗАНИЯ УСЛУГ </H3>
        <p>4.1. Место проведения мастер-класса соответствует месту указанному на он-лайн
          странице оплачиваемого мастер-класса по адресу www.todes.ru/mk. </p>
        <p>4.2. Дата и время проведения мастер-класса соответствует дате и времени указанным
          на он-лайн странице оплачиваемого мастер-класса по адресу www.todes.ru/mk. </p>
        <p>4.3. Исполнитель обязуется: </p>
        <p>4.3.1. Провести мастер-класс, руководствуясь единым стилем «ТОДЕС» и
          фирменным подходом к обучению. </p>
        <p>4.3.2. Предоставить Заказчику достоверную информацию об услугах. </p>
        <p>4.4. Заказчик обязуется: </p>
        <p>4.4.1. Обеспечить понимание и соблюдение Учеником правил поведения в месте
          проведения мастер-классов, объяснить ему необходимость следования указаниям
          руководителя группы. </p>
        <p>4.4.2. Обеспечить наличие у Ученика личных вещей (спортивной одежды и прочих),
          необходимых для участия в мастер-классах. </p>
        <p>4.4.3. Самостоятельно обеспечить прибытие и отбытие Ученика в место и от места
          проведения мастер-классов. </p>
        <p>4.4.4. Оплатить услуги в соответствии с условиями Договора. </p>
        <p>4.4.5. Возместить ущерб в случае причинения Учеником вреда имуществу
          Исполнителя или третьих лиц. </p>
        <p>4.4.6. При заключении Договора и до начала мастер-классов сообщить Исполнителю
          о наличии у Ученика медицинских противопоказаний, препятствующих его
          участию в мастер-классах. </p>
        <p>4.5. Стороны не подписывают акт приёмки услуг или иной документ,
          подтверждающий оказание Исполнителем услуг. В случае, если Заказчик не
          направит Исполнителю письменную претензию по качеству услуг в течение 2 (двух)
          рабочих дней с даты завершения мастер-класса, услуги считаются принятыми
          Заказчиком без замечаний. </p>

        <H3>5. ПОРЯДОК ОПЛАТЫ УСЛУГ </H3>
        <p>5.1. Цена услуг Исполнителя составляет сумму указанную на странице мастер-
          класса. </p>
        <p>5.2. В случае неявки Ученика для непосредственного участия в мастер-классах в
          отсутствие заблаговременного отказа Заказчика от исполнения Договора
          Исполнитель вправе сохранить за собой полученную сумму оплаты, поскольку
          организация Исполнителем для Ученика возможности участия в мастер-классах
          сама по себе является надлежащим оказанием услуг Исполнителем. </p>
        <p>5.3. Заказчик имеет право запросить возврат денежных средств за услуги, если он
          уведомил Исполнителя об отказе от услуг не позднее чем за 2 (два) дня до начала
          оказания услуг. В этом случае Исполнитель обязуется вернуть денежные средства
          Заказчику в полном объеме в течение 10 (десяти) рабочих дней с момента
          получения письменного уведомления об отказе от услуг. </p>

        <H3>6. ОТВЕТСТВЕННОСТЬ СТОРОН </H3>
        <p>6.1. Исполнитель отвечает за качество оказываемых услуг. </p>
        <p>6.2. Исполнитель не несёт ответственность: </p>
        <ul>
          <li><p>за неблагоприятные события, наступившие по вине Заказчика; </p></li>
          <li><p>за неблагоприятные события, наступившие по вине третьих лиц, не
            являющихся контрагентами Исполнителя; </p></li>
          <li><p>за сохранность личных вещей Ученика;</p></li>
          <li><p>за несоответствие услуг субъективным ожиданиям Заказчика или Ученика
            при условии оказания услуг с надлежащим качеством.</p></li></ul>
        <p>6.3. Заказчик несёт ответственность за ненадлежащее исполнение своих обязательств
          по Договору и за сообщение Исполнителю недостоверных сведений. </p>

      </div>
      <Footer />
    </>





  )
}
