import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { SiteUnderConstructionCard } from "./components/SiteUnderConstructionCard";
import { Policy } from "./components/Policy";
import { Contacts } from "./components/Contacts";
import { Offer } from "./components/Offer";
import { EventRedirect } from "./components/EventRedirect";
import { PaymentSuccessModal } from "./components/PaymentSuccessModal";
import { CheckoutRedirect } from "./components/CheckoutRedirect";
import { PaymentFailModal } from "./components/PaymentFailModal";
import { SessionExpiredModal } from "./components/SessionExpiredModal";
import { DownloadTicketRedirect } from "./components/DownloadTicketRedirect";
import { PageNotFound } from "./components/PageNotFound";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <App>
        <SiteUnderConstructionCard />
      </App>
    ),
  },
  {
    path: "/contacts",
    element: (
      <App>
        <Contacts />
      </App>
    ),
  },
  {
    path: "/offer",
    element: (
      <App>
        <Offer />
      </App>
    ),
  },
  {
    path: "/policy",
    element: (
      <App>
        <Policy />
      </App>
    ),
  },
  {
    path: "/event/:id",
    element: (
      <App>
        <EventRedirect />
      </App>
    ),
  },
  {
    path: "/checkout/:id",
    element: (
      <App>
        <CheckoutRedirect />
      </App>
    ),
  },
  {
    path: "/payment/success",
    element: (
      <App>
        <PaymentSuccessModal />
      </App>
    ),
  },
  {
    path: "/payment/fail",
    element: (
      <App>
        <PaymentFailModal />
      </App>
    ),
  },
  {
    path: "/session/:id",
    element: (
      <App>
        <SessionExpiredModal />
      </App>
    ),
  },
  {
    path: "/tickets/download/:id",
    element: (
      <App>
        <DownloadTicketRedirect />
      </App>
    ),
  },
  {
    path: "**",
    element: (
      <App>
        <PageNotFound />
      </App>
    ),
  },
]);

root.render(
  <React.StrictMode>
    <div className="bp5-dark">
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
