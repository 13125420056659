import React from 'react';
import './App.css';
import { MainNavbar } from './components/MainNavbar';
import { Footer } from './components/Footer';

interface Props {
  children: React.ReactNode
}

const App: React.FC<Props> = ({ children }) => {
  return (
    <>
      <MainNavbar />
      {children}

    </>
  );
}

export default App;
