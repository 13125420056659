import { Card, H5, Spinner } from "@blueprintjs/core";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

export const CheckoutRedirect: React.FC = () => {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const session = queryParams.get("session");
    const ref = queryParams.get("ref");
    const price = queryParams.get("price");
    const first = queryParams.get("first");
    const last = queryParams.get("last");
    const mail = queryParams.get("mail");
    const phone = queryParams.get("phone");
    const dob = queryParams.get("dob");
    const consent = queryParams.get("consent");

    useEffect(() => {
        async function fetchRedirectURL() {
            try {
                const response = await fetch(`https://bba2gf9lu1gul7tlnukd.containers.yandexcloud.net/v1/urls/payment-redirect-url/${id}?session=${session}&ref=${ref}&price=${price}&first=${first}&last=${last}&mail=${mail}&phone=${phone}&dob=${dob}&consent=${consent}`);
                if (response.ok) {
                    const { url } = await response.json();
                    window.location.href = url;
                } else {
                    console.error(response);
                }
            } catch (e) {
                console.error(e);
            }
        }

        fetchRedirectURL();
    }, [id, session, ref, price, first, last, mail, phone, dob, consent]);

    return (
        <Card style={{ width: "400px", margin: "auto", marginTop: "100px", textAlign: "center" }}>
            <Spinner />
            <H5>Перенаправление на страницу оплаты...</H5>
        </Card>
        );
};

// robokassaPaymentURL = "https://auth.robokassa.ru/Merchant/Index.aspx"
	// robokassaPassword1  = "4375824e-481b-4ec4-8810-466074c86e37"
	// robokassaPassword2  = "9ae21cde-5ebd-4bad-8608-5cb0fccc817e"
    // robokassaLogin      = "test"
