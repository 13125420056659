import { Button, Dialog, Icon } from "@blueprintjs/core";
import { DIALOG_BODY, DIALOG_FOOTER, DIALOG_FOOTER_ACTIONS } from "@blueprintjs/core/lib/esm/common/classes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const SessionExpiredModal: React.FC = () => {
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate();

    const handleCLose = () => {
        setIsOpen(false);
        navigate("/");
    };

    return (
        <Dialog>
            <div className={DIALOG_BODY} style={{ textAlign: "center" }}>
                <Icon icon={"error"} iconSize={80} intent="danger" style={{ marginBottom: "20px" }} />
                <p style={{ fontSize: "18px", lineHeight: "28px" }}>
                    Время сессии истекло. Пожалуйста, вернитесь на главную страницу и попробуйте снова.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "28px" }}>
                    Одна из причин возникновения данной ситуации может быть связана с тем, что пользователь дважды кликнул по одной и той же ссылке.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "28px" }}>
                    Также причиной может быть некорректная информация о цене, дате рождения, согласии на рассылку или отсутствие обязательных параметров запроса.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "28px" }}>
                    Возможно, заказ уже был создан, или на данный момент нет доступных мест для мастер-класса.
                </p>
                <p style={{ fontSize: "18px", lineHeight: "28px" }}>
                    Во время обработки вашего запроса могли возникнуть технические ошибки, такие как проблемы с регистрацией нового заказа или созданием нового платежа.
                </p>
            </div>
            <div className={DIALOG_FOOTER}>
                <div className={DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={handleCLose} intent="primary" style={{ fontSize: "16px" }}>
                        Закрыть
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
