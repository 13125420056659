import { Button, ButtonGroup, Card, H1, H4 } from "@blueprintjs/core"
import { useEffect, useState } from "react";
import { Footer } from "./Footer";

export const Contacts = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image()
    image.src = "/podcast-2.svg";
    image.onload = () => setImageLoaded(true);
  }, []);

  if (!imageLoaded) return null;

  return (
    <>

      <div className="container">
        <img src="/podcast-2.svg" alt="Work Deadline" style={{ width: '100%', maxWidth: '400px', display: 'block', marginBottom: '20px', pointerEvents: 'none' }} />
        <H1 className="card-header">Контакты</H1>
        <Card className="contactsCard">
          <H4>Осипов Андрей Валерьевич</H4>
          <p>Менеджер проекта</p>
          <p>Павелецкая набережная дом 2, бизнес центр "River Side"</p>
          <p>ИНН: 246525171833</p>
          <p>Расчетный счет: 40802810600170001293</p>
          <p>Банк: ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)</p>
          <ButtonGroup>
            <Button icon={"phone"} text={"Позвонить"} onClick={() => window.location.href = "tel:+7 (965) 191-21-08"} />
            <Button icon={"send-message"} text={"Отправить сообщение"} onClick={() => window.location.href = "mailto:master-class@todes.ru"} />
          </ButtonGroup>

        </Card>
      </div>
      <Footer />
    </>
  )
}
