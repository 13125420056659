import { Card, H5, Spinner } from "@blueprintjs/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const EventRedirect: React.FC = () => {
    const { id } = useParams();

    useEffect(() => {
        async function fetchRedirectURL() {
            try {
                const response = await fetch(`https://bba2gf9lu1gul7tlnukd.containers.yandexcloud.net/v1/urls/typeform-redirect-url/${id}`);
                if (response.ok) {
                    const { url } = await response.json();
                    window.location.href = url;
                } else {
                    console.error(response);
                }
            } catch (e) {
                console.error(e);
            }
        }

        fetchRedirectURL();
    }, [id]);

    return (
        <Card style={{ width: "400px", margin: "auto", marginTop: "100px", textAlign: "center" }}>
            <Spinner />
            <H5>Перенаправление на форму регистрации билетов...</H5>
        </Card>);
};