import { Button, Dialog, Icon } from "@blueprintjs/core";
import { DIALOG_BODY, DIALOG_FOOTER, DIALOG_FOOTER_ACTIONS } from "@blueprintjs/core/lib/esm/common/classes";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const PaymentFailModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const InvId = searchParams.get("InvId");

  const handleCLose = () => {
    setIsOpen(false);
    navigate("/");
  };

  useEffect(() => {
    async function sendStatus() {
      if (!InvId) {
        console.error("InvId not found");
        return;
      }
      try {
        const response = await fetch(`https://bba2gf9lu1gul7tlnukd.containers.yandexcloud.net/v1/payments/reports/fail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentInvoicePublicId: InvId,
            status: "fail",
          }),
        });
        if (response.ok) {
          console.log("Payment status updated");
        } else {
          console.error(response);
        }
      } catch (e) {
        console.error(e);
      }
    }

    sendStatus();
  }, [InvId]);


    return (
        <Dialog
      className="bp4-dark" // Add dark theme to the Dialog
      isOpen={isOpen}
      title="Оплата не прошла"
      onClose={handleCLose}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
    >
      <div className={DIALOG_BODY} style={{ textAlign: "center" }}>
      <Icon icon={"error"} iconSize={80} intent="danger" style={{ marginBottom: "20px" }} />
        <p style={{ fontSize: "18px", lineHeight: "28px" }}>При оплате произошла ошибка.</p>
      </div>
      <div className={DIALOG_FOOTER}>
        <div className={DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleCLose} intent="primary" style={{ fontSize: "16px" }}>
            Закрыть
          </Button>
        </div>
      </div>
    </Dialog>
    );
};